import classNames from "classnames"
import React from "react"
import Impl from "react-phone-number-input"

import styles from "./phone-input.module.scss"

export function prependPlus(phone: string): string {
  return phone[0] === "+" ? phone : `+${phone}`
}

interface Props {
  className?: string
  onChange: (phone: string) => void
  [key: string]: any
}

export function UhPhoneInput({
  className,
  onChange,
  ...rest
}: Readonly<Props>) {
  return (
    <Impl
      className={classNames(styles.phoneInput, className)}
      onChange={onChange}
      {...rest}
    />
  )
}
