import {
  Button,
  Center,
  CloseButton,
  Flex,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import Constants from "Constants/shared.json"
import { DEVICE_TYPE_OPTIONS } from "Shared/constants/testTakingDeviceRequirements"
import { InterviewStudyIcon } from "Shared/icons/InterviewStudyIcon"
import { ClockOutlineIcon } from "Shared/icons/untitled-ui/ClockOutlineIcon"
import { DesktopMobileCustomIcon } from "Shared/icons/untitled-ui/DesktopMobileCustomIcon"
import { DesktopTabletCustomIcon } from "Shared/icons/untitled-ui/DesktopTabletCustomIcon"
import { Laptop01OutlineIcon } from "Shared/icons/untitled-ui/Laptop01OutlineIcon"
import { LogIn04AltSolidIcon } from "Shared/icons/untitled-ui/LogIn04AltSolidIcon"
import { Microphone01OutlineIcon } from "Shared/icons/untitled-ui/Microphone01OutlineIcon"
import { Monitor01OutlineIcon } from "Shared/icons/untitled-ui/Monitor01OutlineIcon"
import { Phone01OutlineIcon } from "Shared/icons/untitled-ui/Phone01OutlineIcon"
import { Tablet02OutlineIcon } from "Shared/icons/untitled-ui/Tablet02OutlineIcon"
import { TabletMobileCustomIcon } from "Shared/icons/untitled-ui/TabletMobileCustomIcon"
import { VideoRecorderOutlineIcon } from "Shared/icons/untitled-ui/VideoRecorderOutlineIcon"
import { ROUTES } from "UsabilityHub/views/routes"
import { AssignmentTag } from "UserCrowd/components/AssignmentTag"
import { capitalizeFirstLetter } from "Utilities/string"
import React, { useCallback } from "react"
import { useNavigate } from "react-router"
import {
  ListUsercrowdOrderAssignmentsResponse,
  useAcceptModeratedStudyOrderAssignment,
  useRejectModeratedStudyOrderAssignment,
} from "~/api/generated/usabilityhub-components"

type Props = {
  assignment: ListUsercrowdOrderAssignmentsResponse["moderated_study_order_assignments"][0]
}

export const ModeratedStudyCard: React.FC<Props> = ({ assignment }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToast()

  const { mutate: acceptAssignment } = useAcceptModeratedStudyOrderAssignment({
    onSuccess: (data) => {
      void queryClient.invalidateQueries(["api", "order_assignments"])
      navigate(
        ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.WELCOME.buildPath({
          moderatedStudyApplicationId: data.moderated_study_application_id,
        })
      )
    },
    onError: () => {
      void queryClient.invalidateQueries(["api", "order_assignments"])
      toast({
        title: "Interview no longer available",
        duration: null,
        status: "error",
      })
    },
  })
  const { mutate: rejectAssignment } = useRejectModeratedStudyOrderAssignment({
    onSuccess: () => {
      return queryClient.invalidateQueries(["api", "order_assignments"])
    },
  })

  const deviceTypes = assignment.device_requirement.device_types

  const deviceTypesLabel =
    deviceTypes.length === DEVICE_TYPE_OPTIONS.length
      ? "Any device"
      : deviceTypes.map(capitalizeFirstLetter).join(" or ")

  const deviceTypesTooltip =
    deviceTypes.length === DEVICE_TYPE_OPTIONS.length
      ? "You can use any device"
      : `You need to use a ${deviceTypes.join(" or ")}`

  const deviceTypeIcon = decideDeviceTypeIcon(deviceTypes)

  const handleAccept = useCallback(() => {
    acceptAssignment({
      pathParams: { id: assignment.id },
    })
  }, [acceptAssignment, assignment.id])

  const handleReject = useCallback(() => {
    rejectAssignment({
      pathParams: { id: assignment.id },
    })
  }, [rejectAssignment, assignment.id])

  return (
    <Flex
      direction="column"
      bg="ds.surface.raised.resting"
      shadow="ds.raised"
      rounded="16px"
      gap={4}
      p={4}
      pos="relative"
      mb={4}
    >
      <CloseButton pos="absolute" top={2} right={2} onClick={handleReject} />

      <Flex gap={2} align="center">
        <Center bg="cyan.100" boxSize={6} rounded="sm">
          <InterviewStudyIcon />
        </Center>

        <Text color="text.primary" fontWeight="bold">
          Interview
        </Text>
      </Flex>
      <Flex align="flex-start" gap={2}>
        <Text color="black" fontSize="2xl" fontWeight="bold" px={2}>
          {assignment.incentive_text}
        </Text>
        <Flex direction="column" gap={2}>
          <Flex wrap="wrap" gap={2}>
            <AssignmentTag
              label={deviceTypesLabel}
              tooltip={deviceTypesTooltip}
              icon={deviceTypeIcon}
            />
            {assignment.device_requirement.device_peripherals.includes(
              "camera"
            ) && (
              <AssignmentTag
                label="Req. camera"
                tooltip="Requires a camera"
                icon={VideoRecorderOutlineIcon}
              />
            )}
            {assignment.device_requirement.device_peripherals.includes(
              "microphone"
            ) && (
              <AssignmentTag
                label="Req. microphone"
                tooltip="Requires a microphone"
                icon={Microphone01OutlineIcon}
              />
            )}
            <AssignmentTag
              tooltip="Meeting duration"
              icon={ClockOutlineIcon}
              label={`${assignment.duration} minutes`}
            />
            {assignment.has_screener && (
              <AssignmentTag
                label="Incl. screener"
                tooltip={`You have to answer a few questions to check if you${"\u2019"}re the right fit`}
                bg="gray.700"
                icon={LogIn04AltSolidIcon}
                iconColor="white"
                color="white"
              />
            )}
          </Flex>
          <Text fontWeight="regular" fontSize="sm">
            {getInviteText(assignment)}{" "}
            <Link
              variant="noUnderline"
              href={Constants.PANELIST_INTERVIEWS_FAQ_URL}
              rel="noopener noreferer"
              target="_blank"
            >
              Learn more
            </Link>
          </Text>
        </Flex>
      </Flex>

      <Flex justify="flex-end">
        <Button colorScheme="brand.primary" onClick={handleAccept}>
          Participate
        </Button>
      </Flex>
    </Flex>
  )
}

type ModeratedStudyDeviceType =
  ListUsercrowdOrderAssignmentsResponse["moderated_study_order_assignments"][0]["device_requirement"]["device_types"]

const decideDeviceTypeIcon = (deviceTypes: ModeratedStudyDeviceType) => {
  const desktop = deviceTypes.includes("desktop")
  const tablet = deviceTypes.includes("tablet")
  const mobile = deviceTypes.includes("mobile")

  if (desktop && tablet && mobile) return Laptop01OutlineIcon

  if (desktop && tablet) return DesktopTabletCustomIcon
  if (desktop && mobile) return DesktopMobileCustomIcon
  if (tablet && mobile) return TabletMobileCustomIcon

  if (desktop) return Monitor01OutlineIcon
  if (tablet) return Tablet02OutlineIcon
  if (mobile) return Phone01OutlineIcon

  return Laptop01OutlineIcon // Default "any device" icon
}

const getInviteText = ({
  has_screener,
  is_handpick,
}: Pick<Props["assignment"], "has_screener" | "is_handpick">) => {
  if (is_handpick) {
    return has_screener
      ? "If eligible, you will receive an invitation to book a time for an online meeting."
      : "You will be asked to apply for an online meeting."
  } else {
    return has_screener
      ? "If eligible, you will be asked to book a time for an online meeting."
      : "You will be asked to book a time for an online meeting."
  }
}
