import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { SubmitButton } from "Components/button/submit-button"
import { JsForm } from "Components/form/form"
import { UhPhoneInput, prependPlus } from "Components/form/phone-input"
import { TextInput } from "Components/form/text-input/text-input"
import { axios, isAxiosErrorWithMessage } from "Services/axios"
import { stripWhitespace } from "Utilities/string"
import { isBlank } from "Utilities/values"
import React, { ChangeEvent, useState } from "react"
import "react-phone-number-input/style.css"
import { Card } from "UserCrowd/components/TestersCard"
import PhoneVerificationApi from "~/api/PhoneVerificationApi"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"

const VerificationCodeLength = 4

type Props = {
  initialPhoneNumber: string | null
}
const TestersPhoneFormImpl: React.FC<Props> = ({ initialPhoneNumber }) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const [phone, setPhone] = useState(initialPhoneNumber ?? "")
  const [code, setCode] = useState("")
  const [hasCodeBeenSent, setHasCodeBeenSent] = useState(false)
  const [isRequestingCode, setIsRequestingCode] = useState(false)
  const [isSubmittingCode, setIsSubmittingCode] = useState(false)

  // TODO: replace with an OpenAPI mutation
  const onRequestCode = async (phoneNumber: string) => {
    try {
      await axios.post(PhoneVerificationApi.requestCode.path(), {
        phone_number: phoneNumber,
      })
      toast({ status: "success", title: "Verification code sent" })
      return true
    } catch (error) {
      if (isAxiosErrorWithMessage(error)) {
        toast({ status: "error", title: error.response.data.message })
      } else {
        reportError(error)
        toast({ status: "error", title: "Sending verification code failed" })
      }
      return false
    }
  }

  // TODO: replace with an OpenAPI mutation
  const onSubmitCode = async (phoneNumber: string, code: string) => {
    try {
      await axios.post(PhoneVerificationApi.verify.path(), {
        phone_number: phoneNumber,
        code,
      })

      queryClient.invalidateQueries(["api", "usercrowd", "panelist", "me"], {
        exact: true,
      })
      toast({ status: "success", title: "Phone number verified" })
    } catch (error) {
      if (isAxiosErrorWithMessage(error)) {
        toast({ status: "error", title: error.response.data.message })
      } else {
        reportError(error)
        toast({ status: "error", title: "Something went wrong" })
      }
    }
  }

  // `phone` is sometimes `undefined`.
  const handlePhoneChange = (phone = "") => {
    setPhone(phone)
    setHasCodeBeenSent(false)
  }

  const handleRequestCode = async () => {
    try {
      setIsRequestingCode(true)
      const codeSendSucceeded = await onRequestCode(phone)
      setHasCodeBeenSent(codeSendSucceeded)
    } finally {
      setIsRequestingCode(false)
    }
  }

  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(stripWhitespace(event.target.value))
  }

  const handleSubmitCode = async () => {
    try {
      setIsSubmittingCode(true)
      await onSubmitCode(phone, code)
    } finally {
      setIsSubmittingCode(false)
    }
  }

  return (
    <Card p={7}>
      <JsForm onSubmit={handleRequestCode}>
        <Flex flexDirection="column" gap={4} align="flex-start">
          <FormControl>
            <FormLabel fontSize="md" fontWeight="medium" htmlFor="phone-number">
              Your mobile phone number
            </FormLabel>
            <UhPhoneInput
              id="phone-number"
              onChange={handlePhoneChange}
              placeholder="Enter your mobile phone number"
              value={prependPlus(phone)}
            />
            <FormHelperText>
              Enter your phone number including the country code.
              <br />A VoIP number is not permitted.
            </FormHelperText>
          </FormControl>
          <SubmitButton
            isDisabled={isBlank(phone) || hasCodeBeenSent}
            isLoading={isRequestingCode}
            loadingAction="Sending"
          >
            Send verification code SMS
          </SubmitButton>
        </Flex>
      </JsForm>
      {hasCodeBeenSent && (
        <JsForm onSubmit={handleSubmitCode}>
          <Box my={4}>
            <Alert status="info">
              <AlertIcon />
              <AlertDescription>
                <Text>
                  You should receive an SMS containing a verification code. If
                  you do not please check your number or{" "}
                  <Button variant="link" onClick={handleRequestCode}>
                    click here
                  </Button>{" "}
                  to resend.
                </Text>
              </AlertDescription>
            </Alert>
          </Box>
          <FormLabel fontSize="md" fontWeight="medium" htmlFor="code">
            Enter your {VerificationCodeLength}-digit code
          </FormLabel>
          <Flex gap={2}>
            <TextInput
              id="code"
              minLength={VerificationCodeLength}
              maxLength={VerificationCodeLength}
              width={VerificationCodeLength + 6 + "rem"}
              fontSize="xl"
              textAlign="center"
              onChange={handleCodeChange}
              value={code}
            />
            <SubmitButton
              isLoading={isSubmittingCode}
              loadingAction="Verifying"
            >
              Verify
            </SubmitButton>
          </Flex>
        </JsForm>
      )}
    </Card>
  )
}

export const TestersPhoneForm: React.FC = () => {
  const { data: currentPanelist } = useCurrentPanelistInfo({})

  if (!currentPanelist) return <Spinner />

  return (
    <TestersPhoneFormImpl initialPhoneNumber={currentPanelist.phone_number} />
  )
}
