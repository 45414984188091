import { useEffect, useState } from "react"

/**
 * Sometimes we're wanting to watch pathnames while we're not yet inside
 * the router, so this is a helper method to get the pathname consistently.
 * This will be made massively more efficient once all browsers support
 * addEventListener("navigate", ....)
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigation/navigate_event
 *
 * @returns {string} The current window location pathname (eg. "/dashboard")
 */

export const usePathname = (): string => {
  const [pathname, setPathname] = useState(window.location.pathname)

  useEffect(() => {
    const body = document.querySelector("body")
    if (body) {
      const observer = new MutationObserver(() => {
        // prevent accidental rerenders when nothing has changed
        if (window.location.pathname !== pathname)
          setPathname(window.location.pathname)
      })
      observer.observe(body, { childList: true, subtree: true })
      return () => observer.disconnect()
    }
  }, [])

  return pathname
}
