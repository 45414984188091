import { Box, CSSReset, Center, Spinner } from "@chakra-ui/react"
import React, { PropsWithChildren, useEffect } from "react"
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"

import { CatchRouterError } from "Components/routes/CatchRouterError"
import Constants from "Constants/shared.json"
import { getApplicationState } from "JavaScripts/state"
import { initializeSentry } from "Services/sentry"
import { PanelistEmulationAlert } from "Shared/components/EmulationAlert/PanelistEmulationAlert"
import { usersSignInPath } from "Shared/constants/routes"
import { usePathname } from "Shared/hooks/usePathname"
import { ModeratedStudyApplicationEndPage } from "UsabilityHub/views/ModeratedStudy/applicant/ApplicationEndPage"
import { ModeratedStudyBookingPage } from "UsabilityHub/views/ModeratedStudy/applicant/BookingPage"
import { CreateApplicationFromPanelAssignment } from "UsabilityHub/views/ModeratedStudy/applicant/CreateApplicationFromPanelAssignment"
import { DeclineInvitationPage } from "UsabilityHub/views/ModeratedStudy/applicant/DeclineInvitationPage"
import { DeclineResearcherReschedulePage } from "UsabilityHub/views/ModeratedStudy/applicant/DeclineResearcherReschedulePage"
import { ModeratedStudyJoinPage } from "UsabilityHub/views/ModeratedStudy/applicant/JoinPage"
import { ModeratedStudyBookingConfirmationPage } from "UsabilityHub/views/ModeratedStudy/applicant/ModeratedStudyBookingConfirmationPage"
import { NotAcceptingParticipantsPage } from "UsabilityHub/views/ModeratedStudy/applicant/NotAcceptingParticipantsPage"
import { PanelistWelcomePage } from "UsabilityHub/views/ModeratedStudy/applicant/PanelistWelcomePage"
import { ModeratedStudyBookingReschedulePage } from "UsabilityHub/views/ModeratedStudy/applicant/ReschedulePage"
import { RootApplicationLayout } from "UsabilityHub/views/ModeratedStudy/applicant/RootApplicationLayout"
import { ModeratedStudyScreenerPage } from "UsabilityHub/views/ModeratedStudy/applicant/ScreenerPage"
import { ROUTES as SHARED_ROUTES } from "UsabilityHub/views/routes"
import { RecordingsAnnouncementBanner } from "UserCrowd/components/RecordingsAnnouncementBanner/RecordingsAnnouncementBanner"
import { UsercrowdIntercomWrapper } from "UserCrowd/components/UsercrowdIntercomWrapper"
import { useUsercrowdFavicon } from "UserCrowd/hooks/useUsercrowdFavicon"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { NotFound } from "./NotFound"
import { AgreementRoute } from "./agreement/Route"
import { AssignmentRoute } from "./assignment/$uniqueId/Route"
import { DashboardRoute } from "./dashboard/Route"
import { HistoryRoute } from "./history/Route"
import { TermsAndConditionsRoute } from "./legal/terms/Route"
import { NotificationPreferencesRoute } from "./notification_preferences/Route"
import { PayoutsRoute } from "./payouts/Route"
import { PhoneRoute } from "./phone/Route"
import { ProfileRoute } from "./profile/Route"
import { ROUTES } from "./routes"
import { AccountSettingsRoute } from "./settings/account/Route"
import { NotificationsSettingsRoute } from "./settings/notifications/Route"
import { ProfileSettingsRoute } from "./settings/profile/Route"
import { EditPasswordRoute } from "./users/password/edit/Route"
import { ResetPasswordRoute } from "./users/password/new/Route"
import { SignInRoute } from "./users/sign_in/Route"
import { SignUpRoute } from "./users/sign_up/Route"
import { VerificationRoute } from "./verification/Route"
import { WaitlistRoute } from "./waitlist/Route"

const UserCrowdMain: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { data: currentPanelist } = useCurrentPanelistInfo({})
  // Initialize Sentry once we have a userId from the /me endpoint
  const currentPanelistId = currentPanelist?.id
  useEffect(() => {
    if (!currentPanelistId) return

    initializeSentry({ id: currentPanelistId })
  }, [currentPanelistId])

  const pathname = usePathname()
  const validPathEndings = [ROUTES.DASHBOARD.path, ROUTES.ASSIGNMENT.path]
  // this gives us a bit of flexibility on the pages, we just add to
  // the list above if it's a simple route.
  // The split section below (and startsWith) is because some of our
  // routes have ids (eg Assignment) which is a bit more difficult to
  // exact match
  const showRecordingsBanner = validPathEndings.some((pathEnding) =>
    pathname.startsWith(pathEnding.split(":")[0])
  )

  const { isEmulatingUser } = getApplicationState()

  return (
    <Box
      as="main"
      minH="full"
      maxH="full"
      overflowY="auto"
      color="text.primary"
    >
      {isEmulatingUser && <PanelistEmulationAlert />}
      <CSSReset />
      {currentPanelistId && <FavIconComponent />}
      {showRecordingsBanner && (
        <RecordingsAnnouncementBanner mt="3.5rem" mb="-3.5rem" zIndex={1} />
      )}
      {children}
    </Box>
  )
}

const FavIconComponent: React.FC = () => {
  useUsercrowdFavicon()
  return null
}

function RequiresUser({ children }: PropsWithChildren) {
  const { data: user, isLoading } = useCurrentPanelistInfo({})

  if (!isLoading && !user?.id) return <Navigate to={usersSignInPath()} />

  return <>{children}</>
}

function RequiresVerifiedUser({ children }: PropsWithChildren) {
  const { data: user, isLoading, isError } = useCurrentPanelistInfo({})

  if (isLoading || isError) return null

  if (!user?.id) {
    return <Navigate to={usersSignInPath()} />
  } else if (user.panelist_state === "waitlisted") {
    return <Navigate to={ROUTES.WAITLIST.path} />
  } else if (user.panelist_state === "accepted") {
    return <Navigate to={ROUTES.VERIFICATION.path} />
  }

  return <>{children}</>
}

function RequiresOnboardedUser({ children }: PropsWithChildren) {
  const { data: user, isLoading } = useCurrentPanelistInfo(
    {},
    { cacheTime: Infinity, staleTime: Infinity }
  )

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  } else if (!user?.id) {
    return <Navigate to={ROUTES.USERS.SIGN_IN.path} />
  }

  const phoneNumberRequired =
    user.phone_number_required_for_panel && !user.phone_number
  const hasEverCompletedProfile = user.completed_profile_at

  if (user.panelist_state === "waitlisted") {
    return <Navigate to={ROUTES.WAITLIST.path} />
  } else if (user.panelist_state === "accepted") {
    return <Navigate to={ROUTES.VERIFICATION.path} />
  } else if (!user.agreed_to_current_tester_terms) {
    return <Navigate to={ROUTES.AGREEMENT.path} />
  } else if (!hasEverCompletedProfile) {
    return <Navigate to={ROUTES.PROFILE.path} />
  } else if (phoneNumberRequired) {
    return <Navigate to={ROUTES.PHONE.path} />
  }

  return children
}

function Root() {
  return (
    <>
      <ModalContextProvider>
        <Outlet />
      </ModalContextProvider>
    </>
  )
}

const UserCrowdRouter = (
  <Route
    element={<Root />}
    errorElement={
      <CatchRouterError supportEmail={Constants.TESTER_SUPPORT_EMAIL_ADDRESS} />
    }
  >
    <Route
      element={
        <UsercrowdIntercomWrapper>
          <Box px={[4, "30px"]} py="30px" pt="100px" maxW="1142px" mx="auto">
            <Outlet />
          </Box>
        </UsercrowdIntercomWrapper>
      }
    >
      <Route
        path={ROUTES.WAITLIST.path}
        element={
          <RequiresUser>
            <WaitlistRoute />
          </RequiresUser>
        }
      />
      <Route
        path={ROUTES.VERIFICATION.path}
        element={
          <RequiresUser>
            <VerificationRoute />
          </RequiresUser>
        }
      />
      <Route
        path={ROUTES.AGREEMENT.path}
        element={
          <RequiresVerifiedUser>
            <AgreementRoute />
          </RequiresVerifiedUser>
        }
      />
      <Route
        path={ROUTES.PROFILE.path}
        element={
          <RequiresVerifiedUser>
            <ProfileRoute />
          </RequiresVerifiedUser>
        }
      />
      <Route
        path={ROUTES.PHONE.path}
        element={
          <RequiresVerifiedUser>
            <PhoneRoute />
          </RequiresVerifiedUser>
        }
      />
      <Route
        path={ROUTES.NOTIFICATION_PREFERENCES.path}
        element={
          <RequiresOnboardedUser>
            <NotificationPreferencesRoute />
          </RequiresOnboardedUser>
        }
      />
      <Route
        path={ROUTES.DASHBOARD.path}
        element={
          <RequiresOnboardedUser>
            <DashboardRoute />
          </RequiresOnboardedUser>
        }
      />
      <Route
        path={ROUTES.PAYOUTS.path}
        element={
          <RequiresOnboardedUser>
            <PayoutsRoute />
          </RequiresOnboardedUser>
        }
      />
      <Route
        path={ROUTES.HISTORY.path}
        element={
          <RequiresOnboardedUser>
            <HistoryRoute />
          </RequiresOnboardedUser>
        }
      />
      <Route
        path={ROUTES.SETTINGS.ACCOUNT.path}
        element={
          <RequiresOnboardedUser>
            <AccountSettingsRoute />
          </RequiresOnboardedUser>
        }
      />
      <Route
        path={ROUTES.SETTINGS.NOTIFICATIONS.path}
        element={
          <RequiresOnboardedUser>
            <NotificationsSettingsRoute />
          </RequiresOnboardedUser>
        }
      />
      <Route
        path={ROUTES.SETTINGS.PROFILE.path}
        element={
          <RequiresOnboardedUser>
            <ProfileSettingsRoute />
          </RequiresOnboardedUser>
        }
      />
      <Route
        path={ROUTES.ASSIGNMENT.path}
        element={
          <RequiresOnboardedUser>
            <AssignmentRoute />
          </RequiresOnboardedUser>
        }
      />
    </Route>

    {/* Interstitial for panelists accepting an assignment */}
    <Route
      path={SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLY.path}
      element={
        <RequiresOnboardedUser>
          <CreateApplicationFromPanelAssignment />
        </RequiresOnboardedUser>
      }
    />

    {/* Moderated Study Participant Routes */}
    <Route
      element={
        <RequiresOnboardedUser>
          <UsercrowdIntercomWrapper>
            <RootApplicationLayout />
          </UsercrowdIntercomWrapper>
        </RequiresOnboardedUser>
      }
    >
      <Route
        path={SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.WELCOME.path}
        element={<PanelistWelcomePage />}
      />
      <Route
        path={SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.QUESTIONS.path}
        element={<ModeratedStudyScreenerPage />}
      />
      <Route
        path={SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.THANKYOU.path}
        element={<ModeratedStudyApplicationEndPage />}
      />
      <Route
        path={SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.CLOSED.path}
        element={<NotAcceptingParticipantsPage />}
      />
      <Route
        path={SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.BOOK.path}
        element={<ModeratedStudyBookingPage />}
      />
      <Route
        path={
          SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.RESCHEDULE_DECLINE
            .path
        }
        element={<DeclineResearcherReschedulePage />}
      />
      <Route
        path={
          SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.INVITATION_DECLINE
            .path
        }
        element={<DeclineInvitationPage />}
      />
      <Route
        path={SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.CONFIRMED.path}
        element={<ModeratedStudyBookingConfirmationPage />}
      />
      <Route
        path={SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.RESCHEDULE.path}
        element={<ModeratedStudyBookingReschedulePage />}
      />
      <Route
        path={SHARED_ROUTES.INTERVIEW_APPLICATIONS.APPLICATION.JOIN.path}
        element={<ModeratedStudyJoinPage />}
      />
    </Route>

    {/* Unauthenticated routes */}
    <Route path={ROUTES.USERS.SIGN_IN.path} element={<SignInRoute />} />
    <Route path={ROUTES.USERS.SIGN_UP.path} element={<SignUpRoute />} />
    <Route
      path={ROUTES.USERS.PASSWORD.NEW.path}
      element={<ResetPasswordRoute />}
    />
    <Route
      path={ROUTES.USERS.PASSWORD.EDIT.path}
      element={<EditPasswordRoute />}
    />
    <Route
      path={ROUTES.LEGAL.TERMS.path}
      element={<TermsAndConditionsRoute />}
    />

    <Route path="*" element={<NotFound />} />
  </Route>
)

const userCrowdRouterObject = createBrowserRouter(
  createRoutesFromElements(UserCrowdRouter)
)

export const UserCrowd: React.FC = () => {
  return (
    <UserCrowdMain>
      <RouterProvider router={userCrowdRouterObject} />
    </UserCrowdMain>
  )
}
