import React from "react"
import { Navigate } from "react-router"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../routes"

export const VerificationRoute: React.FC = () => {
  const { data: user, isLoading, isError } = useCurrentPanelistInfo({})

  if (isLoading || isError) return null

  if (user.panelist_state !== "accepted") {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return <h1>Verify yo email</h1>
}
