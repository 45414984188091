import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberInput,
  NumberInputField,
  useToast,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { ageToYearOfBirth } from "Utilities/user"
import React, {
  Dispatch as ReactDispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { useDemographicProfileUpdateYearOfBirth } from "~/api/generated/usabilityhub-components"

interface YearOfBirthFieldProps {
  initialYearOfBirth: number | null
  setIsYearOfBirthAnswered: ReactDispatch<SetStateAction<boolean>>
}

export const YearOfBirthField: React.FC<
  React.PropsWithChildren<YearOfBirthFieldProps>
> = ({ initialYearOfBirth, setIsYearOfBirthAnswered }) => {
  const toast = useToast()

  const [yearOfBirth, setYearOfBirth] = useState<number | null>(
    initialYearOfBirth
  )
  const [blur, setBlur] = useState(false)
  const [modified, setModified] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  const { mutate: updateYearOfBirth, isLoading } =
    useDemographicProfileUpdateYearOfBirth({
      onSuccess: () => {
        setIsYearOfBirthAnswered(true)
      },
      onError: () => {
        toast({
          title: "Something went wrong. Please refresh the page and try again.",
          duration: null,
          status: "error",
        })
      },
    })

  const onBlur = () => {
    setBlur(true)
  }

  const onChange = (value: string) => {
    setYearOfBirth(Number(value))
    setModified(true)
  }

  useEffect(() => {
    if (!blur) {
      return
    }
    setBlur(false)

    if (!modified) {
      return
    }

    if (
      yearOfBirth === null ||
      yearOfBirth > ageToYearOfBirth(Constants.TESTER_MIN_AGE)! ||
      yearOfBirth < ageToYearOfBirth(Constants.TESTER_MAX_AGE)!
    ) {
      setErrorMessage(
        "You must be at least 18 years old to participate in UserCrowd."
      )
      setIsYearOfBirthAnswered(false)
      return
    } else {
      setErrorMessage(null)
    }

    updateYearOfBirth({ body: { year_of_birth: yearOfBirth } })
  }, [
    blur,
    errorMessage,
    modified,
    toast,
    yearOfBirth,
    setIsYearOfBirthAnswered,
  ])

  return (
    <FormControl isRequired isInvalid={!!errorMessage}>
      <FormLabel htmlFor="year_of_birth">Year of birth</FormLabel>
      <NumberInput
        id="year_of_birth"
        value={yearOfBirth || ""}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={isLoading}
      >
        <NumberInputField />
        <NumberInput />
      </NumberInput>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
