import "JavaScripts/debug"
import "JavaScripts/polyfills"
import createUhStore from "Redux/create-uh-store"
import initializeState from "Redux/initialize-state"
import flash from "Redux/reducers/flash"
import flashInitializer from "Redux/reducers/flash/initialize-state"
import { mountSpa } from "Shared/mountSpa"
import "Stylesheets/fonts/grenette-regular-pro.css"
import "Stylesheets/fonts/mint-grotesk.css"
import { UserCrowdProvider } from "UserCrowd/UserCrowdProvider"
import { UserCrowd } from "UserCrowd/views/UserCrowd"
import { getJsonFromScriptElement } from "Utilities/get-json-from-script-element"
import { combineReducers } from "redux"

const rawState = getJsonFromScriptElement("initial_state")
const store = createUhStore(
  combineReducers({
    flash,
  }),
  initializeState(rawState, { flash: flashInitializer })
)

mountSpa(UserCrowd, {}, store, UserCrowdProvider)
