import {
  ComponentWithAs,
  IconProps,
  Tag,
  TagLeftIcon,
  TagProps,
  Tooltip,
} from "@chakra-ui/react"
import React from "react"

type AssignmentTagProps = {
  label: string
  tooltip: string
  icon: ComponentWithAs<"svg", IconProps>
  iconColor?: string
} & TagProps

export const AssignmentTag: React.FC<AssignmentTagProps> = ({
  label,
  tooltip,
  icon,
  iconColor = "gray.500",
  ...rest
}) => {
  return (
    <Tooltip hasArrow rounded="md" placement="top" label={tooltip}>
      <Tag colorScheme="gray" pt="1px" {...rest}>
        <TagLeftIcon color={iconColor} as={icon} boxSize={3} mr={1} />

        {label}
      </Tag>
    </Tooltip>
  )
}
