interface TesterGuideline {
  heading: string
  guideline: string[]
}

export const testerGuidelines: TesterGuideline[] = [
  {
    heading: "Read questions and instructions carefully",

    guideline: [
      `Many tests are similar, but every test is different. Please read the
      questions and instructions carefully to ensure you know what you are
      required to do.`,
    ],
  },
  {
    heading: "Take your time",
    guideline: [
      `Don${"\u2019"}t rush through tests. Every test must be given your best effort.
      Moderate rushing may result in some of your responses being rejected.
      Speeding through tests will result in being banned from our platform.`,
    ],
  },
  {
    heading: "Be thorough, clear and specific",
    guideline: [
      `Take the time to provide as much information as you can when giving
      written responses. Make your answers clear by writing in sentences
      (where appropriate).`,
    ],
  },
  {
    heading: "Be honest, polite, and professional.",
    guideline: [
      `You don${"\u2019"}t have to give positive feedback but you should give feedback like you would to someone you met in-person. Eg:`,
      `“The logo looks unprofessional” or “I find the navigation very confusing” are great answers.`,
      `“This sucks”, “I hate it”, or answers containing profanity, are unacceptable.`,
    ],
  },
]
