import { Link, ListItem, Text } from "@chakra-ui/react"
import React from "react"

import { USERCROWD_TERMS_AND_CONDITIONS_DOCUMENT } from "Constants/legal-documents"
import Constants from "Constants/shared.json"
import {
  Document,
  DocumentIntro,
  DocumentSection,
  OrderedDocumentList,
} from "Shared/components/Document/Document"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { getDateString } from "Utilities/date-formats"

export const TermsAndConditionsDocument = () => (
  <Document title="UserCrowd Terms and Conditions">
    <DocumentIntro>
      <Text>
        Last updated on{" "}
        <strong>
          {getDateString(USERCROWD_TERMS_AND_CONDITIONS_DOCUMENT.lastUpdated)}
        </strong>
        .
      </Text>
      <Text>
        This online platform (<strong>Platform</strong>) is operated by
        UsabilityHub Pty Ltd ACN 158 305 205 trading as Lyssna or its successors
        and assignees (<strong>UsabilityHub</strong>, <strong>Lyssna</strong>,{" "}
        <strong>UserCrowd</strong>, <strong>we</strong>, <strong>our</strong> or{" "}
        <strong>us</strong>). The Platform is available at{" "}
        <ExternalLink>usercrowd.com</ExternalLink> (<strong>Site</strong>) and
        may be available through other addresses or channels.
      </Text>
      <Text>
        The Platform connects research participants (
        <strong>Participants</strong>, <strong>you</strong>, or{" "}
        <strong>your</strong>) with Businesses for the purpose of undertaking
        research studies, including usability testing, market research,
        interviews, and other related research services (
        <strong>Service</strong>). Individuals may join and create an account on
        the Site to become members of our research panel and undertake research
        studies, providing feedback, preferences, and opinions (
        <strong>User Research</strong>).
      </Text>
    </DocumentIntro>
    <DocumentSection title="1. Acceptance">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          These Platform terms and conditions (<strong>Terms</strong>):
          <OrderedDocumentList>
            <ListItem>
              Are between us and you (<strong>Participant</strong> or{" "}
              <strong>you</strong>);
            </ListItem>
            <ListItem>
              set out the terms and conditions upon which we agree to grant you
              a right to use the UserCrowd Platform and Service; and
            </ListItem>
            <ListItem>
              is binding on you from the date (<strong>Effective Date</strong>)
              on which we provide you with an account to enable you to access
              and use the Service (<strong>Account</strong>) until the date on
              which your Account is terminated in accordance with these Terms (
              <strong>Term</strong>).
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
        <ListItem>
          Please read these Terms carefully and immediately terminate your
          Account if you do not agree to them.
        </ListItem>
        <ListItem>
          By creating an Account and/or accessing and/or using the Service, you:
          <OrderedDocumentList>
            <ListItem>
              warrant to us that you have reviewed these Terms, including our
              Privacy Policy, available on the UserCrowd Platform, and you
              understand them;
            </ListItem>
            <ListItem>
              acknowledge and agree that these Terms incorporate by reference
              the terms of any rules and guidelines as set out on the UserCrowd
              Platform or as provided to you from time to time (
              <strong>Participant Guidelines</strong>);
            </ListItem>
            <ListItem>
              warrant to us that you have the legal capacity to enter into a
              legally binding agreement and you are over 18 years of age;
            </ListItem>
            <ListItem>
              warrant to us that you have the authority to act on behalf of any
              person or entity for whom you are using the Service, and you are
              deemed to have agreed to these Terms on behalf of any entity for
              whom you use the Service;
            </ListItem>
            <ListItem>
              warrant to us that you have all hardware, software, and services
              which are necessary to access and use the Service; and
            </ListItem>
            <ListItem>
              agree to use the Service in accordance with these Terms.
            </ListItem>
          </OrderedDocumentList>
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="2. Registration and Account ">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          You will be required to create an Account with us in order to access
          and use the Service and you will need to provide the required personal
          information including without limitation contact details and certain
          demographic information as requested at the time of creating your
          Account and throughout the Term. You must ensure that any personal
          information you give us when creating an Account is accurate and
          up-to-date. All personal information that you give to us will be
          treated in accordance with our Privacy Policy.
        </ListItem>
        <ListItem>
          When you create an Account, you will be required to supply a password.
          You agree that we may alter or update your password at any time during
          the Term. It is your responsibility to keep your Account details
          confidential. You are liable for all content posted and all activity
          on your Account, including purchases or payouts made using your
          Account details, and you must immediately notify us of any
          unauthorized use of your Account.
        </ListItem>
        <ListItem>
          You must not create more than one Account. If we detect that you have
          created more than one Account we will terminate all of your Accounts,
          including your original Account in accordance with clause 15(b) of
          these Terms.
        </ListItem>
        <ListItem>
          At our sole discretion, we may refuse to allow any person to create an
          Account.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="3. Collection Notice">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          We collect personal information about you in order to allow you to
          access and use the UserCrowd Platform, to invite you to and allow you
          to participate in User Research, to contact and communicate with you,
          to provide a payout, to respond to your enquiries and for other
          purposes set out in our Privacy Policy.
        </ListItem>
        <ListItem>
          We may disclose that information to third party service providers who
          help us deliver our services (including information technology service
          providers, data storage, web-hosting and server providers,
          professional advisors, payment systems operators, and our business
          partners) or as required by law. If you do not provide this
          information we may not be able to provide the Service to you. In
          certain circumstances, we may disclose your personal information to
          third parties located, or who store data, outside Australia.
        </ListItem>
        <ListItem>
          Our Privacy Policy contains further information about: (i) how we
          store and use your personal information; (ii) how you can access and
          seek correction of your personal information; (iii) how you can make a
          privacy-related complaint; and (iv) our complaint handling process. By
          providing personal information to us, you consent to us collecting,
          holding, using, and disclosing your personal information in accordance
          with our Privacy Policy.
        </ListItem>
        <ListItem>
          Notwithstanding anything to the contrary in these Terms or elsewhere,
          we may monitor, analyze and compile statistical and performance
          information based on and/or related to your use of the Service, in an
          aggregated and anonymized format (<strong>Analytics</strong>). You
          agree that we may make such Analytics publicly available, provided
          that it: (i) does not contain identifying information; and (ii) is not
          compiled using a sample size small enough to make the underlying data
          identifiable. We and/or our licensors own all rights, title, and
          interest in and to the Analytics and all related UserCrowd Platform,
          technology, documentation, and content provided in connection with the
          Analytics, including all intellectual property rights in the
          foregoing.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="4. License">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          We grant you a non-exclusive, non-transferable, non-sublicensable
          (except as otherwise permitted under these Terms), personal and
          revocable license to access and use the Service for your own personal
          purposes for the Term (<strong>License</strong>).
        </ListItem>
        <ListItem>You agree that:</ListItem>
        <OrderedDocumentList>
          <ListItem>
            we reserve the right at any time and from time to time to: (i)
            refuse any request in relation to the Service that we deem
            inappropriate, unreasonable, illegal, or otherwise non-compliant
            with these Terms; and/or (ii) modify or discontinue, temporarily or
            permanently, access to the Service (or any part thereof) with
            notice.{" "}
          </ListItem>
        </OrderedDocumentList>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="5. User Research  and Notifications">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          Businesses who wish to receive feedback on their products and services
          may engage us to enable them to receive responses from you. (
          <strong>Business Customers</strong>)
        </ListItem>
        <ListItem>
          When we receive a request from a Business Customer for Participants,
          we will send notifications with an invitation to participate to
          Participants based on our queuing system which takes into account a
          number of variables including but not limited to your demographic
          information and your rating (<strong>Rating</strong>) on the platform.
        </ListItem>
        <ListItem>
          The invitation notification will provide an overview of the nature of
          the User Research, the incentive offered for participating in the User
          Research in accordance with our Participant Guidelines, and the
          estimated time required to complete the User Research.
        </ListItem>
        <ListItem>
          An invitation notification sent to you to complete User Research will
          expire once the number of Participants requested by the Business
          Customer has been fulfilled in accordance with our Participant
          Guidelines. At this time your invitation to complete the User Research
          will be revoked.
        </ListItem>
        <ListItem>
          You agree to keep your contact information up-to-date and that we will
          provide you with UserCrowd Platform notifications to alert you about
          an invite to participate in new User Research on the Platform. The
          notification will be sent via the UserCrowd Platform or via any other
          currently active communication channel. You may update your
          communication preferences via the “Settings” feature (or similar) on
          our UserCrowd Platform. Some notification settings may be locked or
          unavailable as they are required for the functionality of the
          UserCrowd Platform
        </ListItem>
        <ListItem>
          When accepting and completing User Research you will need to provide
          accurate information and quality responses as set out in our
          Participant Guidelines.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="6. Participant Rating">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>We will assign a Rating to your Account.</ListItem>
        <ListItem>
          Your Rating is based on an assessment by us and our Business Customers
          of your User Research responses and whether in our sole discretion,
          your User Research responses have been provided in accordance with our
          Participant Guidelines.
        </ListItem>
        <ListItem>
          If you have any questions, comments, or complaints about your Rating
          please contact us on the details below and we will endeavor to respond
          to your query but you are not automatically entitled to a review of
          your Rating.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="7. Incentives and Payouts">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          When you complete User Research in accordance with our Participant
          Guidelines we will automatically credit your Account balance with the
          incentive applicable to the User Research completed (
          <strong>Incentive</strong>). For some types of User Research, this may
          occur after the User Research response has been reviewed.
        </ListItem>
        <ListItem>
          The Incentives in your Account balance will be removed if your Account
          is terminated in accordance with clause 15 of these Terms.
        </ListItem>
        <ListItem>
          You can view your Account balance via the dashboard on the UserCrowd
          Platform.
        </ListItem>
        <ListItem>
          Where you have an Account balance of $10 or more you may request a
          payout via the UserCrowd Platform which will transfer your Account
          balance into US dollars (<strong>Payout</strong>).
        </ListItem>
        <ListItem>
          A Payout will be paid via your currently linked PayPal account. It is
          your responsibility to ensure your PayPal details are accurate and
          up-to-date.
        </ListItem>
        <ListItem>
          You can view a history of your Incentive and Payout transactions via
          the UserCrowd Platform.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="8. Restrictions">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          You must not access or use the Service except as permitted by the
          License and you must not do, or omit to do, any act that would or
          might invalidate or be inconsistent with our intellectual property
          rights in the Service or UserCrowd Platform or with our Business
          Customers intellectual property rights. Without limiting the foregoing
          provisions, you must not:
        </ListItem>
        <OrderedDocumentList>
          <ListItem>
            resell, assign, transfer, distribute or provide others with access
            to the Service;
          </ListItem>
          <ListItem>
            alter, remove or tamper with any intellectual property rights,
            trademarks, any patent or copyright notices, any confidentiality
            legend or notice, any numbers or any other means of identification
            used on or in relation to the Service or UserCrowd Platform and
            including of our Business Customers;
          </ListItem>
          <ListItem>
            use the Service to enter User Research responses, transmit, publish
            or communicate material that includes profanities, is defamatory,
            offensive, abusive, indecent, menacing or unwanted;
          </ListItem>
          <ListItem>
            copy, alter, modify, create derivative works from, reproduce to a
            third party, reverse assemble, or reverse engineer the Service or
            UserCrowd Platform;
          </ListItem>
          <ListItem>
            use the Service in any way which is in breach of any applicable
            local, state, federal and international laws and regulations (
            <strong>Laws</strong>) or which infringes any person's rights,
            including intellectual property rights including those of our
            Business Customers;
          </ListItem>
          <ListItem>
            use the Service in any way that damages, interferes with or
            interrupts the supply of the Service or any of our services;
          </ListItem>
          <ListItem>
            reveal your Account password to others or allow others to use your
            Account;
          </ListItem>
          <ListItem>
            use the Service to send spam or make fraudulent offers of goods or
            services;
          </ListItem>
          <ListItem>
            use the Service to carry out security breaches or disruptions of a
            network or any other person’s use of the Service; or.
          </ListItem>
          <ListItem>
            use the Service in breach of any person’s privacy (such as by way of
            identity theft or “phishing”).
          </ListItem>
        </OrderedDocumentList>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="9. Your Data">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          In order to complete your User Research responses (
          <strong>Data</strong>) on our Platform, you may be required to upload,
          submit, or transmit relevant information and content in any format (
          <strong>User Content</strong>) using our Platform. By participating in
          User Research and making available any User Content on or through our
          Platform or via a third party platform, you assign to us the right to
          use, copy, adapt, edit, distribute, and display such Data on the
          Platform, including to allow us to transfer our rights via license or
          assignment to our Business Customers.
        </ListItem>
        <ListItem>
          You agree that you are solely responsible for all Data that you make
          available on or through our Platform. You represent and warrant that:
        </ListItem>
        <OrderedDocumentList>
          <ListItem>
            you are either the sole and exclusive owner of all User Content or
            you have all rights, licenses, consents, and releases that are
            necessary to grant to us the rights in such Data as contemplated by
            these Terms; and
          </ListItem>
          <ListItem>
            neither the Data nor the posting, uploading, publication, submission
            or transmission of the User Content or our use of the Data on,
            through or by means of our Platform will infringe, misappropriate or
            violate a third party’s intellectual property rights, or rights of
            publicity or privacy, or result in the violation of any applicable
            law or regulation.
          </ListItem>
          <ListItem>
            We do not endorse or approve and are not responsible for any Data.
            We may, at any time at our sole discretion, remove any Data.
          </ListItem>
        </OrderedDocumentList>
        <ListItem>
          <strong>Moral Rights</strong>: If you or any of your personnel have
          any moral rights (as defined in the Copyright Act 1968 (Cth)) (
          <strong>Moral Rights</strong>) in any Data, you (and you will ensure
          that your personnel) consent to the infringement of those Moral Rights
          by us or our personnel.
        </ListItem>
        <ListItem>
          <strong>General</strong>: You must, at all times, ensure the integrity
          of your Data and that your use of your Data is compliant with all
          Laws. You represent and warrant that: (i) you have obtained all
          necessary rights, releases, and permissions to provide all your Data
          to us and to grant the rights granted to us in these Terms; and (ii)
          your Data and its transfer to and use by us as authorized by you under
          these Terms do not violate any Laws (including those relating to
          export control and electronic communications) or rights of any third
          party, including any intellectual property rights, rights of privacy,
          or rights of publicity, and any use, collection, and disclosure
          authorized in these Terms is not inconsistent with the terms of any
          applicable privacy policies. We assume no responsibility or liability
          for your Data, and you shall be solely responsible for your Data and
          the consequences of using, disclosing, storing, or transmitting it.
        </ListItem>
        <ListItem>
          <strong>Removals</strong>: We have no obligation to monitor any
          content uploaded to the Service. Nonetheless, if we deem such action
          necessary for any reason, we may (without limiting our other rights)
          remove your Data from the Service. We have no liability to you for
          removing your Data from the Service.
        </ListItem>
        <ListItem>
          <strong>Security</strong>: We implement security procedures to help
          protect your Data from security attacks. However, you understand that
          use of the Service necessarily involves the transmission of your Data
          over networks that are not owned, operated, or controlled by us, and
          we are not responsible for any of your Data being lost, altered,
          intercepted, or stored across such networks. We cannot guarantee that
          our security procedures will be error-free, that transmission of your
          Data will always be secure or that unauthorized third parties will
          never be able to defeat our security measures or those of our
          third-party service providers.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="10. Technical problems">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          In case of technical problems, you must make all reasonable efforts to
          investigate and diagnose problems before contacting us. If you still
          need help, please check the support we provide as stated on our
          UserCrowd Platform, or failing that please email us at{" "}
          <Link
            href={`mailto:${Constants.TESTER_SUPPORT_EMAIL_ADDRESS}`}
            isExternal
          >
            {Constants.TESTER_SUPPORT_EMAIL_ADDRESS}
          </Link>
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="11. Your Responsibilities and Obligations">
      <Text>You must:</Text>
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          at your expense, provide us with all reasonable assistance and
          cooperation in order for us to supply the Service in an efficient and
          timely manner;
        </ListItem>
        <ListItem>
          provide accurate, current and complete information during the
          registration process and keep your profile accurate and up-to-date;
        </ListItem>
        <ListItem>
          provide accurate and quality responses and in your User Research
          answers as per our Participant Guidelines set out on the UserCrowd
          Platform; and
        </ListItem>
        <ListItem>
          at your expense, make any changes to your Computing Environment, such
          as system upgrades, that may be required to support the delivery and
          operation of any Service.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="12. Confidentiality">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          <strong>Definition</strong>: The term Confidential Information means
          all trade secrets, know-how, inventions, developments, designs,
          software, and other financial, business, or technical information of a
          Business Customer that is disclosed to you via the Platform.
        </ListItem>
        <ListItem>
          <strong>Confidentiality</strong>: You must treat all information
          relating to our Business Customers and their intellectual property on
          the Platform as Confidential Information. You must keep this
          Confidential Information secret and only access the Confidential
          Information under the specific rights granted by these Terms or to the
          extent permitted by law. You must not use, copy, reproduce, or
          disclose any of our Business Customers’ Confidential Information
          without their written consent unless required by law.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="13. Intellectual Property Rights">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          All intellectual property (including copyright) developed, adapted,
          modified or created by us or our personnel (including in connection
          with these Terms, the UserCrowd Platform and the Service) will at all
          times vest, or remain vested, in us.
        </ListItem>
        <ListItem>
          You must not do, or omit to do, any act that would or might invalidate
          or be inconsistent with our intellectual property rights in the
          Service, UserCrowd Platform, or with our Business Customers’
          intellectual property rights in any Confidential Information they
          upload to the Platform.
        </ListItem>
        <ListItem>You must not, without our prior written consent:</ListItem>
        <OrderedDocumentList>
          <ListItem>
            copy or use, in whole or in part, any of our intellectual property
            or our Business Customers’ intellectual property;
          </ListItem>
          <ListItem>
            reproduce, retransmit, distribute, disseminate, sell, publish,
            broadcast or circulate any of our intellectual property or our
            Business Customers’ intellectual property to any third party; or
          </ListItem>
          <ListItem>
            breach any intellectual property rights connected with the UserCrowd
            Platform including our Business Customer’s intellectual property
            rights, the UserCrowd Platform or the Service, including (without
            limitation) altering or modifying any of our or our Business
            Customer’s intellectual property; causing any of our Business
            Customer’s intellectual property to be framed or embedded in another
            website; or creating derivative works from any of our or our
            Business Customers intellectual property.
          </ListItem>
        </OrderedDocumentList>
        <ListItem>
          This clause 13 will survive the termination of your Account.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="14. Indemnity and liability">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          Despite anything to the contrary, to the maximum extent permitted by
          the law:
        </ListItem>
        <OrderedDocumentList>
          <ListItem>
            our maximum aggregate liability arising from or in connection with
            these Terms (including the Service or the subject matter of these
            Terms) will be limited to, and must not exceed $1; and
          </ListItem>
          <ListItem>
            we will not be liable to you for any loss of profit (including
            anticipated profit), loss of revenue, loss of business, loss of
            goodwill, loss of opportunity, loss of savings (including
            anticipated savings), loss of reputation, loss of use and/or loss or
            corruption of data, whether under statute, contract, equity, tort
            (including negligence), indemnity or otherwise.
          </ListItem>
        </OrderedDocumentList>
        <ListItem>
          Despite anything to the contrary, to the maximum extent permitted by
          the law, we have no liability, and you release and discharge us from
          all liability, arising from or in connection with any:
          <OrderedDocumentList>
            <ListItem>
              injury, damage or loss to any person or property;
            </ListItem>
            <ListItem>failure or delay in providing the Service;</ListItem>
            <ListItem>
              unavailability, outage or interruption to the Service or your
              Computing Environment; or
            </ListItem>
            <ListItem>
              breach of these Terms or any Laws, where caused or contributed to
              by any:
            </ListItem>
            <ListItem>
              event or circumstance beyond our reasonable control;
            </ListItem>
            <ListItem>
              a fault, defect, error or omission in your Computing Environment
              or Data; or
            </ListItem>
            <ListItem>
              act or omission of you, your related parties or a third-party
              service provider,
            </ListItem>
          </OrderedDocumentList>
          and, in any event, any error, omission or lack of suitability (or the
          absence of, or reduction in, any anticipated result, outcome or
          benefit) with respect to the Service.
        </ListItem>
        <ListItem>
          Certain legislation including the Australian Consumer Law (
          <strong>ACL</strong>) in the Competition and Consumer Act 2010 (Cth),
          and similar consumer protection laws and regulations may confer you
          with rights, warranties, guarantees, and remedies relating to the
          provision of our services which cannot be excluded, restricted or
          modified (<strong>Statutory Rights</strong>). Nothing in these Terms
          attempts to exclude, restrict, or modify your Statutory Rights as a
          consumer under the ACL. Any and all other warranties or conditions
          which are not guaranteed by the ACL are expressly excluded where
          permitted, except to the extent such warranties and conditions are
          fully expressed in these Terms.
        </ListItem>
        <ListItem>You acknowledge and agree that:</ListItem>
        <OrderedDocumentList>
          <ListItem>
            you use the Service and any associated programs and files at your
            own risk;
          </ListItem>
          <ListItem>
            we may use third-party service providers to host the Service. If the
            providers of third party applications or services cease to make
            their services or programs available on reasonable terms, we may
            cease providing any affected features without liability or entitling
            you to any refund, credit, or other compensation;
          </ListItem>
          <ListItem>
            the Service may use third party products, facilities or services. We
            do not make any warranty or representation in respect of the
            third-party products, facilities, or services;
          </ListItem>
          <ListItem>
            we do not guarantee that any file or program available for download
            and/or execution from or via the Service is free from viruses or
            other conditions which could damage or interfere with Data, hardware
            or UserCrowd Platform with which it might be used;
          </ListItem>
          <ListItem>
            from time to time, we may make certain services and/or features
            available to you for use which are still in their beta stage (Beta
            Services); Beta Services have not been fully tested and are provided
            on an ‘as is’ basis; and, to the fullest extent permitted by Law, we
            make no representations, warranties or guarantees in relation to
            such Beta Services; and
          </ListItem>
          <ListItem>
            we may pursue any available equitable or other remedy against you if
            you breach any provision of these Terms.
          </ListItem>
        </OrderedDocumentList>
        <ListItem>
          Despite anything to the contrary, to the maximum extent permitted by
          the law, you must indemnify us and hold us harmless from and against
          any loss, cost, liability, or damage, howsoever arising, suffered, or
          incurred by us, and arising from or in connection with any claim
          relating to your Data, User Content, your use of the Service or any
          breach of these Terms by you.
        </ListItem>
        <ListItem>
          This clause 14 will survive termination of the Account.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="15. Termination">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          You may terminate your Account by using the “Cancel Account” feature
          (or similar) on the UserCrowd Platform or by sending an email to the
          address at the end of the Terms. You are solely responsible for
          properly terminating your Account. Your credits will be removed from
          your Account on termination.
        </ListItem>
        <ListItem>
          We may, at any time and at our sole discretion, suspend or terminate
          your Account for any reason. We will generally alert you when we take
          such action and give you a reasonable opportunity to remedy any breach
          of these Terms, but if we determine that your actions endanger the
          operation of the Service or other users, we may suspend or terminate
          your Account immediately without notice. If you or we terminate your
          Account you will not be able to request a payout of your credits
          following the termination.
        </ListItem>
      </OrderedDocumentList>
    </DocumentSection>
    <DocumentSection title="16. General">
      <OrderedDocumentList listStyleType="lower-latin">
        <ListItem>
          <strong>Force Majeure</strong>: If a party (
          <strong>Affected Party</strong>) is unable to perform any of its
          obligations under these Terms due to an event or circumstance beyond
          its reasonable control (<strong>Force Majeure</strong>) and it gives
          the other party prompt written notice of such, the Affected Party’s
          obligations shall be suspended to the extent necessary. The Affected
          Party must use reasonable efforts to limit the impact of the event on
          its performance and must continue to perform its obligations in full
          as soon as the Force Majeure ceases.
        </ListItem>
        <ListItem>
          <strong>Non-solicitation</strong>: You must not solicit or entice
          away, any person or organization that was our actual or prospective,
          client, employee, contractor, representative, agent, or developer
          during the Term.
        </ListItem>
        <ListItem>
          <strong>Disputes</strong>: Neither party may commence court
          proceedings relating to any dispute arising from, or in connection
          with, these Terms without first meeting with a senior representative
          of the other party to seek (in good faith) to resolve that dispute
          (unless that party is seeking urgent interlocutory relief or the
          dispute relates to compliance with this provision).
        </ListItem>
        <ListItem>
          <strong>Notices</strong>: Any notice given under these Terms must be
          in writing and addressed to us at the details set out below or to you
          at the details provided when setting up your Account. Any notice may
          be sent by standard post or email and will be deemed to have been
          served on the expiry of 48 hours in the case of post, or on the next
          business day in the case of email.
        </ListItem>
        <ListItem>
          <strong>Waiver</strong>: Any failure or delay by a party in exercising
          a power or right (either wholly or partly) in relation to these Terms
          does not operate as a waiver or prevent a party from exercising that
          power or right or any other power or right. A waiver must be in
          writing.
        </ListItem>
        <ListItem>
          <strong>Relationship of parties</strong>: These Terms are not intended
          to create a partnership, joint venture, or agency relationship between
          the parties.
        </ListItem>
        <ListItem>
          <strong>Severance</strong>: If a provision of these Terms is held to
          be void, invalid, illegal, or unenforceable, that provision is to be
          read down as narrowly as necessary to allow it to be valid or
          enforceable, failing which, that provision (or that part of that
          provision) will be severed from these Terms without affecting the
          validity or enforceability of the remainder of that provision or the
          other provisions.
        </ListItem>
        <ListItem>
          <strong>Assignment</strong>: You must not assign any rights or
          obligations under these Terms, whether in whole or in part, without
          our prior written consent.
        </ListItem>
        <ListItem>
          <strong>Entire agreement</strong>: These Terms contain the entire
          understanding and agreement between you and us in respect of their
          subject matter.
        </ListItem>
        <ListItem>
          <strong>Amendment</strong>: We may, at any time and at our discretion,
          vary these Terms by publishing varied terms on the UserCrowd Platform.
        </ListItem>
        <ListItem>
          <strong>Governing law</strong>: These Terms are governed by the laws
          of Victoria. You irrevocably and unconditionally submit to the
          exclusive jurisdiction of the courts operating in Victoria and any
          courts entitled to hear appeals from those courts and waive any rights
          to object to proceedings being brought in those courts. The Service
          may be accessed in Australia and overseas. We make no representation
          that the Service complies with the laws (including intellectual
          property laws) of any country outside of Australia other than the
          General Data Protection Regulation 2016/679 where applicable to your
          data. If you access the Service from outside Australia, you do so at
          your own risk and are responsible for complying with the laws in the
          place you access the Service.
        </ListItem>
      </OrderedDocumentList>
      <Text>For any questions or notices, please contact us at:</Text>
      <Text>UsabilityHub Pty Ltd ACN 158 305 205 trading as Lyssna</Text>
      <Text>
        Email:{" "}
        <Link
          href={`mailto:${Constants.TESTER_SUPPORT_EMAIL_ADDRESS}`}
          isExternal
        >
          {Constants.TESTER_SUPPORT_EMAIL_ADDRESS}
        </Link>
      </Text>
    </DocumentSection>
  </Document>
)
