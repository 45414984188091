import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Spinner,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { prependPlus } from "Components/form/phone-input"
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "Shared/components/Card/Card"
import React from "react"
import { useForm } from "react-hook-form"
import PhoneNumberInput from "react-phone-number-input"
import * as Yup from "yup"
import {
  CurrentPanelistInfoResponse,
  GetPanelistSettingsResponse,
  useCurrentPanelistInfo,
  useGetPanelistSettings,
  useUpdatePanelistAccountDetails,
} from "~/api/generated/usabilityhub-components"

export const PanelistDetails: React.FC = () => {
  const { data: user } = useCurrentPanelistInfo({})
  const { data: settings } = useGetPanelistSettings({})

  if (!user || !settings) {
    return (
      <Center minH="100px">
        <Spinner />
      </Center>
    )
  }

  return <PanelistDetailsForm user={user} settings={settings} />
}

const PanelistDetailsSchema = Yup.object({
  name: Yup.string().required(),
  preferred_name: Yup.string().required(),
  email: Yup.string().required(),
  paypal_email_address: Yup.string().required().nullable(),
  email_product_updates: Yup.boolean().required(),
})

type PanelistDetailsFormType = Yup.InferType<typeof PanelistDetailsSchema>

type PanelistDetailsFormProps = {
  user: CurrentPanelistInfoResponse
  settings: GetPanelistSettingsResponse
}

const PanelistDetailsForm: React.FC<PanelistDetailsFormProps> = ({
  user,
  settings,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { mutateAsync: updatePanelistDetails } =
    useUpdatePanelistAccountDetails({
      onSuccess: async () => {
        await Promise.allSettled([
          queryClient.invalidateQueries(
            ["api", "usercrowd", "panelist", "me"],
            { exact: true }
          ),
          queryClient.invalidateQueries(
            ["api", "usercrowd", "panelist", "settings"],
            { exact: true }
          ),
        ])

        toast({
          status: "success",
          title: "Your settings have been saved",
        })
      },
      onError: (error) => {
        if (error.status === 401) {
          // If you haven't recently authenticated this endpoint will return 401
          // which is handled by the global error handler, so no need to show a toast.
          return
        }

        toast({
          status: "error",
          title: error.payload.message,
        })
      },
    })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PanelistDetailsFormType>({
    resolver: yupResolver(PanelistDetailsSchema),
    defaultValues: {
      name: user.name,
      preferred_name: user.preferred_name ?? "",
      email: user.email,
      paypal_email_address: settings.paypal_email_address,
      email_product_updates: settings.email_product_updates,
    },
  })

  const onSubmit = async (data: PanelistDetailsFormType) => {
    try {
      await updatePanelistDetails({
        body: data,
      })
    } catch (error) {
      // The mutation's onError handles this case, we just don't want it
      // to bubble up to the form.
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader size="lg">Your details</CardHeader>
        <CardBody>
          <Flex direction="column" gap={6}>
            <FormControl isRequired isInvalid={!!errors.name}>
              <FormLabel fontSize="sm">Full name</FormLabel>
              <Input {...register("name")} placeholder="Your full name" />
              {errors.name && (
                <FormErrorMessage>{errors.name.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.preferred_name}>
              <FormLabel fontSize="sm">Preferred name</FormLabel>
              <Input
                {...register("preferred_name")}
                placeholder="Your preferred name"
              />
              {errors.preferred_name && (
                <FormErrorMessage>
                  {errors.preferred_name.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel fontSize="sm">Email</FormLabel>
              <Input {...register("email")} placeholder="Your email" />
              {errors.email && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={!!errors.paypal_email_address}>
              <FormLabel fontSize="sm">PayPal email address</FormLabel>
              <Input
                {...register("paypal_email_address")}
                placeholder="Your PayPal email"
              />
              {errors.paypal_email_address && (
                <FormErrorMessage>
                  {errors.paypal_email_address.message}
                </FormErrorMessage>
              )}
              <FormHelperText>
                All payments will be sent to this PayPal account
              </FormHelperText>
            </FormControl>

            {user.phone_number !== null && (
              <FormControl isRequired>
                <FormLabel fontSize="sm">Phone number</FormLabel>
                <Box ml={1}>
                  <PhoneNumberInput
                    initialValueFormat="national"
                    disabled
                    onChange={() => {}}
                    value={prependPlus(user.phone_number)}
                    inputComponent={Input}
                  />
                </Box>
                <FormHelperText>
                  Your phone number is only used to verify your country
                </FormHelperText>
                <FormHelperText>A VoIP number is not permitted</FormHelperText>
              </FormControl>
            )}

            <FormControl isInvalid={!!errors.email_product_updates}>
              <Checkbox size="sm" {...register("email_product_updates")}>
                Send me notifications on product updates
              </Checkbox>
              {errors.email_product_updates && (
                <FormErrorMessage>
                  {errors.email_product_updates.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Flex>

          <CardFooter>
            <Button
              isLoading={isSubmitting}
              loadingText="Saving changes"
              type="submit"
              colorScheme="brand.primary"
            >
              Save changes
            </Button>
          </CardFooter>
        </CardBody>
      </Card>
    </form>
  )
}
