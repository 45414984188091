import { Flex, Text } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React from "react"

import { UserCrowdNavbar } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"

interface Props {
  readonly heading: string
  readonly subheading?: string
  readonly showBackButton?: boolean
  readonly isOnboarding?: boolean
}

export const TestersHeader = ({
  heading,
  subheading,
  showBackButton = true,
  isOnboarding = false,
}: Props) => {
  const variant = isOnboarding
    ? "onboarding"
    : showBackButton
      ? "inner-page"
      : "dashboard"

  return (
    <Flex as="header" direction="column" mb={8}>
      <UserCrowdNavbar variant={variant} />
      <Flex
        direction={["column", "row"]}
        justify="space-between"
        align={["flex-start", "center"]}
      >
        <Heading as="h1" textStyle="ds.display.emphasized">
          {heading}
        </Heading>
        {subheading && <Text>{subheading}</Text>}
      </Flex>
    </Flex>
  )
}
