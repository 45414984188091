import { getEnvState } from "../../application/javascripts/state/index"
import { initializeIntercom } from "./initialize-intercom.js"

const { USERCROWD_INTERCOM_APP_ID } = getEnvState()

type IntercomUser = {
  id: number
  name: string
  preferred_name: string | null
  email: string
  created_at: string
  country: string | null
  intercom_user_hash: string
}

// Intercom is used directly on Usercrowd, not through Segment like on the main UH app.
export const installIntercom = (
  user: IntercomUser,
  customAttributes: Record<string, string | boolean | number>
) => {
  // Don't do any Intercom stuff in dev (or CI!)
  if (process.env.NODE_ENV === "development") {
    window.Intercom = () => {}
    return
  }

  initializeIntercom(USERCROWD_INTERCOM_APP_ID)

  Intercom("boot", {
    app_id: USERCROWD_INTERCOM_APP_ID,
    name: user.preferred_name ?? user.name,
    email: user.email,
    user_id: user.id,
    created_at: Math.floor(new Date(user.created_at).getTime() / 1000), // Intercom wants epoch seconds
    country: user.country,
    user_hash: user.intercom_user_hash,
    ...customAttributes,
  })
}
