import React, { PureComponent } from "react"

import { LinkButton } from "Components/button/link-button"
import { H1 } from "UserCrowd/components/Heading/Heading"
import { TestersNotificationsForm } from "UserCrowd/components/TestersNotificationsForm/TestersNotificationsForm"
import { TestersOnboardingContainer } from "UserCrowd/components/TestersOnboardingContainer"
import { ROUTES } from "UserCrowd/views/routes"
import { setUserCrowdDocumentTitle } from "Utilities/set-user-crowd-document-title"

export class NotificationPreferencesRoute extends PureComponent {
  componentDidMount() {
    setUserCrowdDocumentTitle("Set up notifications")
  }

  render() {
    return (
      <TestersOnboardingContainer>
        <H1>Notification preferences</H1>
        <TestersNotificationsForm />
        <LinkButton
          colorScheme="brand.primary"
          href={ROUTES.DASHBOARD.path}
          width="full"
        >
          Complete
        </LinkButton>
      </TestersOnboardingContainer>
    )
  }
}
