import React from "react"
import { Navigate } from "react-router"

import { Spinner } from "@chakra-ui/react"
import { TestersHeader } from "UserCrowd/components/TestersHeader"
import { TestersOnboardingContainer } from "UserCrowd/components/TestersOnboardingContainer"
import { ROUTES } from "UserCrowd/views/routes"
import { Helmet } from "react-helmet"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { TestersPhoneForm } from "./PhoneForm"

export const PhoneRoute: React.FC = () => {
  const { data } = useCurrentPanelistInfo({})

  if (!data) {
    return <Spinner />
  }

  if (data.phone_number !== null) {
    return <Navigate to={ROUTES.NOTIFICATION_PREFERENCES.path} />
  }

  return (
    <TestersOnboardingContainer>
      <Helmet title="Verify your phone number" />

      <TestersHeader
        heading="Phone verification"
        showBackButton={false}
        isOnboarding
      />

      <TestersPhoneForm />
    </TestersOnboardingContainer>
  )
}
