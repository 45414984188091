import { Flex, Icon, Image, Text } from "@chakra-ui/react"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { Heading } from "DesignSystem/components"
import { pluralize } from "Utilities/string"
import React from "react"

import { DemographicAttributeGroupIcon } from "./DemographicAttributeGroupIcon"
import { DemographicQuestion } from "./DemographicQuestion"
import { GranularLocation } from "./GranularLocation"
import { findUnansweredQuestions } from "./findUnansweredQuestions"
import completionImageLeft from "./profile-complete-01.png"
import completionImageRight from "./profile-complete-02.png"
import completionImageCompact from "./profile-complete-compact.png"

type Props = {
  totalRemainingItems: number
  nextQuestion: ReturnType<typeof findUnansweredQuestions>[number] | null
  hasGranularLocation: boolean
  locationSkipped: boolean
  questionsSkipped: number
  onNext: () => void
  onSkip: (isLocation: boolean) => void
}

export const ProfileQuestionSection: React.FC<Props> = ({
  totalRemainingItems,
  nextQuestion,
  hasGranularLocation,
  locationSkipped,
  questionsSkipped,
  onNext,
  onSkip,
}) => {
  if (totalRemainingItems === 0) {
    return (
      <Flex
        justify="space-between"
        align="center"
        h="160px"
        rounded="12px"
        bg="ds.background.neutral.resting"
        overflow="hidden"
      >
        <Image
          display={["none", "block"]}
          maxH="160px"
          src={completionImageLeft}
        />

        <Flex direction="column" align="center" w="100%" gap={4}>
          <Image
            display={["block", "none"]}
            w="88px"
            src={completionImageCompact}
          />

          <Flex direction="column" align="center" w="100%" gap={2}>
            <Flex align="center" gap={2}>
              <Icon as={CheckCircleIcon} boxSize={5} color="ds.icon.success" />
              <Heading
                as="h2"
                textStyle="ds.display.primary"
                whiteSpace="nowrap"
              >
                Great job!
              </Heading>
            </Flex>

            <Text textStyle="ds.paragraph.primary" align="center">
              Your profile is now 100% complete.
            </Text>
          </Flex>
        </Flex>

        <Image
          display={["none", "block"]}
          maxH="160px"
          src={completionImageRight}
        />
      </Flex>
    )
  }

  const showingGranularLocation = !hasGranularLocation && !locationSkipped
  const groupLabel = showingGranularLocation
    ? "General"
    : nextQuestion?.group_name ?? "General"

  return (
    <Flex
      direction="column"
      minH="160px"
      bg="ds.background.neutral.resting"
      rounded="12px"
      p={4}
      gap={3}
    >
      <Flex justify="space-between" align="center">
        <Flex gap={2} align="center">
          <DemographicAttributeGroupIcon groupName={groupLabel} />
          <Text
            color="ds.text.subtle"
            textStyle="ds.interface.xsmall"
            noOfLines={1}
          >
            {groupLabel}
          </Text>
        </Flex>

        <Flex align="center" gap={1} fontSize="xs" display={["none", "flex"]}>
          <Flex
            justify="center"
            align="center"
            minH={4}
            minW={4}
            px={1.5}
            py="3px"
            rounded="full"
            bg="ds.background.neutral.resting"
            textStyle="ds.interface.small"
          >
            {totalRemainingItems}
          </Flex>
          <Text textStyle="ds.paragraph.secondary">
            {pluralize(totalRemainingItems, "question")} remaining
          </Text>
        </Flex>

        <Flex
          display={["flex", "none"]}
          justify="center"
          align="center"
          minH={4}
          minW={4}
          px={1.5}
          py="3px"
          rounded="full"
          bg="ds.background.neutral.resting"
          textStyle="ds.interface.small"
        >
          {Math.min(questionsSkipped + 1, totalRemainingItems)} /{" "}
          {totalRemainingItems}
        </Flex>
      </Flex>

      <Flex direction={["column", "row"]} align="flex-start" gap={3}>
        {/* If user hasn't entered granular location, always show that first */}
        {!hasGranularLocation && !locationSkipped ? (
          <GranularLocation
            onSkip={totalRemainingItems > 1 ? () => onSkip(true) : false}
            onSave={onNext}
          />
        ) : (
          nextQuestion && (
            <DemographicQuestion
              question={nextQuestion}
              onSkip={totalRemainingItems > 1 ? () => onSkip(false) : false}
              onSave={onNext}
            />
          )
        )}
      </Flex>
    </Flex>
  )
}
