import { installIntercom } from "UserCrowd/utils/intercom"
import React, { PropsWithChildren, useEffect } from "react"
import {
  useCurrentPanelistInfo,
  usePanelistStats,
} from "~/api/generated/usabilityhub-components"

export const UsercrowdIntercomWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { data: currentPanelist } = useCurrentPanelistInfo({})
  const { data } = usePanelistStats({})

  useEffect(() => {
    if (!currentPanelist || !data) return

    installIntercom(currentPanelist, {
      usercrowd: true,
      upcoming_interview: data.upcoming_interview,
      recent_interview: data.recent_interview,
    })

    // Clean up the widget when leaving
    return () => Intercom("shutdown")
  }, [currentPanelist, data?.recent_interview, data?.upcoming_interview])

  return children
}
