import {
  FormControl,
  FormHelperText,
  FormLabel,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { GooglePlacesAutocomplete } from "UserCrowd/components/GooglePlacesAutocomplete/GooglePlacesAutocomplete"
import React, {
  Dispatch as ReactDispatch,
  SetStateAction,
  useState,
} from "react"
import { useUpdatePanelistLocation } from "~/api/generated/usabilityhub-components"
import { ConfirmChangeCountryModal } from "./ConfirmChangeCountryModal"

interface CountryFieldProps {
  initialPlaceId: string | null
  initialFormattedAddress: string | null
  setIsCountryAnswered: ReactDispatch<SetStateAction<boolean>>
  shouldResetPhoneVerification: boolean
}

export const CountryField: React.FC<
  React.PropsWithChildren<CountryFieldProps>
> = ({
  initialPlaceId,
  initialFormattedAddress,
  setIsCountryAnswered,
  shouldResetPhoneVerification,
}) => {
  const queryClient = useQueryClient()
  const [resetAutocomplete, setResetAutocomplete] = useState(0)
  const [savedPlaceId, setSavedPlaceId] = useState<string | null>(
    initialPlaceId
  )
  const [formattedAddress, setFormattedAddress] = useState<string | null>(
    initialFormattedAddress
  )

  const toast = useToast()

  const { onOpen, isOpen, onClose } = useDisclosure()

  const { mutate: updateGranularLocation } = useUpdatePanelistLocation({
    onSuccess: (response) => {
      setIsCountryAnswered(true)

      if (shouldResetPhoneVerification && response.require_phone_validation) {
        // Do this last because resetting these values causes the main router
        // to redirect to the confirm mobile page and we can't update this
        // component after it's unmounted
        return Promise.all([
          queryClient.invalidateQueries(
            ["api", "usercrowd", "panelist", "me"],
            {
              exact: true,
            }
          ),
          queryClient.invalidateQueries(
            ["api", "usercrowd", "panelist", "settings"],
            { exact: true }
          ),
        ])
      }
    },
    onError: () => {
      toast({
        title: "Something went wrong. Please refresh the page and try again.",
        duration: null,
        status: "error",
      })
    },
  })

  const handleLocationChange = (placeId: string, formattedAddress: string) => {
    setSavedPlaceId(placeId)
    setFormattedAddress(formattedAddress)

    if (initialFormattedAddress && initialPlaceId) {
      // We want to show the user a modal to warn them that changing their
      // address may require re-verifying their phone
      onOpen()
    } else {
      // Otherwise we can immediately persist it
      handlePersistLocation(placeId)
    }
  }

  const handlePersistLocation = (placeId: string) => {
    updateGranularLocation({
      body: {
        google_place_id: placeId,
      },
    })
  }

  return (
    <>
      <FormControl isInvalid={false} isRequired id="location">
        <FormLabel htmlFor="location">Where do you live?</FormLabel>
        <GooglePlacesAutocomplete
          id="location"
          key={`autocomplete-${resetAutocomplete}`}
          initialFormattedAddress={initialFormattedAddress}
          initialPlaceId={initialPlaceId}
          onChange={handleLocationChange}
        />
        <FormHelperText>
          Please select the suburb, town or postcode that you currently live in.
        </FormHelperText>
      </FormControl>

      <ConfirmChangeCountryModal
        fromName={initialFormattedAddress ?? ""}
        toName={formattedAddress ?? ""}
        onConfirm={(c: boolean) => {
          if (c) {
            if (!savedPlaceId) {
              throw new Error("placeId should always be set by this point")
            }

            handlePersistLocation(savedPlaceId)
          } else {
            // Since they cancelled the change, reinitialize the autocomplete so we get the
            // old address back.
            setResetAutocomplete((r) => r + 1)
            setSavedPlaceId(initialPlaceId)
          }
          onClose()
        }}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
