import { Box, Checkbox, Stack, useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { SubmitButton } from "Components/button/submit-button"
import { Card } from "UserCrowd/components/TestersCard"
import { TestersHeader } from "UserCrowd/components/TestersHeader"
import { TestersOnboardingContainer } from "UserCrowd/components/TestersOnboardingContainer"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import { Navigate } from "react-router-dom"
import {
  useAgreeToCurrentTerms,
  useCurrentPanelistInfo,
} from "~/api/generated/usabilityhub-components"
import TESTER_AGREEMENT_CLAUSES from "./agreement.json"

type Clause = (typeof TESTER_AGREEMENT_CLAUSES)[number]["value"]

type FormInput = Record<Clause, boolean>

const allAccepted = (values: FormInput) =>
  TESTER_AGREEMENT_CLAUSES.every(({ value }) => values[value])

export const AgreementRoute: React.FC = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { register, handleSubmit, watch } = useForm<FormInput>()

  const { data: user } = useCurrentPanelistInfo({})
  const { mutate: agreeToTerms, isLoading } = useAgreeToCurrentTerms({
    onSuccess: () => {
      return queryClient.invalidateQueries(
        ["api", "usercrowd", "panelist", "me"],
        {
          exact: true,
        }
      )
    },
    onError: () => {
      toast({
        title: "There was a problem agreeing to the terms. Please try again.",
        status: "error",
      })
    },
  })

  const onAgree = (agreed: FormInput) => {
    agreeToTerms({ body: { agreed } })
  }

  const isComplete = user?.agreed_to_current_tester_terms
  const isAccepted = allAccepted(watch())

  if (isComplete) {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <>
      <Helmet title="Agree to the rules" />
      <TestersOnboardingContainer>
        <TestersHeader
          heading="Tester agreement"
          subheading="Please read and agree to these rules"
          showBackButton={false}
          isOnboarding
        />
        <form onSubmit={handleSubmit(onAgree)}>
          <Card>
            <Stack spacing={3} direction="column">
              {TESTER_AGREEMENT_CLAUSES.map((option) => (
                <Checkbox
                  variant="mdWithSmFont"
                  key={option.value}
                  {...register(option.value, { required: true })}
                >
                  {option.label}
                </Checkbox>
              ))}
            </Stack>
          </Card>
          <Box textAlign="right" pt={5}>
            <SubmitButton isDisabled={!isAccepted} isLoading={isLoading}>
              Continue
            </SubmitButton>
          </Box>
        </form>
      </TestersOnboardingContainer>
    </>
  )
}
